import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import analyse from "../../img/link.svg";
import celular from "./devices.svg";
import sis from "../../img/metrica.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDollar,
  faBoxOpen,
  faChartBar,
  faStore,
  faCheckCircle,
  faClone,
  faLinkSlash,
  faLink,
  faRefresh,
  faAngleRight,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import Header from '../../componets/Header';
import { toast } from 'react-toastify';
import { apiUrl } from '../../comp/ApiUrl';
import { StaticDialog } from 'react-st-modal';
import Skeleton from '../../comp/Skeleton';

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
     l:'',
     e:'',
     c:false,
     captcha:'',
     tc:'',
     lc:false
    };
    this.inputRef = React.createRef();
  }
  componentDidMount(){
    document.title = "Encurtar Link | Encurtador de LINK&URL"
  }
  render() {
    return (
      <>
        <section className="hero-header primary-header slider-header" id="home">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="hero-header-content">
                  <h1>Cole a URL para ser encurtada</h1>
                  <p>
                  EncurtarLink.com.br é uma ferramenta para encurtar URLs e gerar links curtos
                  Com o encurtador de URL é possível criar um link encurtado fácil de compartilhar
                  </p>
                  <div className="input-form">
{this.state.e != '' ? <>

  <div className="ipt-nome-usr">
<div className='url'>
<input type='text' value={this.state.e} ref={this.inputRef}/>
</div>
                 

                      <button className='btn-ini' onClick={(e)=>{
                       
                 this.inputRef.current.select()
                 document.execCommand('copy');
                 toast.success("Link copiado!")
                      }}><FontAwesomeIcon icon={faClone} />  Copiar</button>
</div>

</> : <>
  <div className="ipt-nome-usr">
<div className='url'>
<input type='text' value={this.state.l} onChange={(e)=>{
this.setState({l:e.target.value})

}} placeholder='Link:'/>
</div>
                 

                      <button className='btn-ini' onClick={(e)=>{
                       var l = this.state.l;
                   
                   

                       const regex = /^(https?|http?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;


                       if(l.trim().length == 0){
                        toast.error("Preencha o campo de link!")
                        return false;
                       }
                       if(regex.test(l) == false){
                        toast.error("O link é inválido!")
                       return false;
                       }


                       fetch(`${apiUrl}/captcha/${new Date().getTime()}`, {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                          'Content-Type': 'application/json',
                        }
                      })
                        .then(response =>{
if(response.status == 403){
  toast.info("Você excedeu o número permitido de geração de captcha. Por favor, aguarde alguns minutos antes de tentar novamente.")
          
  return;
}
                          
if(response.status == 200){
  response.json().then(data=>{
  this.setState({
  captcha:data.c,
  lc:true
  })
  })
}
                      }).catch(()=>{
                        toast.error("Falha na comunicação com o servidor!")
                      })


                       this.setState({
                        captcha:`${apiUrl}/captcha/${new Date().getTime()}`,
                        c:true
                      })

                


                      



                      }}>
                        <FontAwesomeIcon icon={faLink}/>
                        Encurtar</button>
</div>
</>}



                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="hero-header-image">
                  <img src={analyse} style={{ width: 643, height: 435 }} alt="#" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about-us" className="work section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <h2>Motivos para usar o EncurtarLink.com.br</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span>1</span>
                  </div>
                  <h3>Facil De usar</h3>
                  <p>
                  Encurte URLs longas rapidamente com uma interface intuitiva e simples, ideal para compartilhar links em redes sociais e mensagens.
                 </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span>2</span>
                  </div>
                  <h3>Estatísticas</h3>
                  <p>
                  Acompanhe cliques e obtenha insights detalhados sobre a origem e o desempenho dos seus links para otimizar suas estratégias de marketing.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work last">
                  <div className="serial">
                    <span>3</span>
                  </div>
                  <h3>Segurança</h3>
                  <p>Links protegidos contra spam e conteúdo malicioso, garantindo a segurança dos seus usuários e a integridade dos seus dados compartilhados.</p>
                </div>
              </div>
           

              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work last">
                  <div className="serial">
                    <span>4</span>
                  </div>
                  <h3>Customização</h3>
                  <p>Personalize URLs para refletir seu conteúdo, melhorando a confiança dos usuários e tornando os links mais memoráveis e profissionais.</p>
                </div>
              </div>


            </div>
          </div>
        </section>


     

        <section className="services-area section" id="features">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-12">
                <div className="ft-1 info-media right">
                  <img src={celular} alt="Desktop and Mobile App" />
                </div>
              </div>
              <div className="col-lg-5 col-12">
                <div className="info-text">
                  <h2 className="main-title">Acompanhe Suas Métricas em Tempo Real</h2>
                  <p className="des"> Com nossas ferramentas avançadas de métricas, você tem controle total sobre o desempenho dos seus links. Monitore cliques, analise o tráfego e obtenha insights valiosos para otimizar suas estratégias, tudo em tempo real e de forma simples.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="info-text">
                  <h2 className="main-title">Somos Multiplataforma: Acesse de Qualquer Lugar</h2>
                  <p className="des">Com nosso serviço multiplataforma, você pode encurtar links e otimizar suas tarefas em qualquer dispositivo. Seja no computador, com nossa versão web, na extensão do navegador ou através do nosso aplicativo, garantimos uma experiência fluida e eficiente. Conecte-se onde quiser, como quiser!</p>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="ft-2 info-media right">
                  <img src={sis} alt="Desktop and Mobile App" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <StaticDialog
        isOpen={this.state.c}
        title="resolva o captcha"
        onAfterClose={(result) => {
       this.setState({tc:'',c:false})
        }}
    >
      <div style={{
        position:'relative',
        padding:10
      }}>

        <div style={{
          position:'relative',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}>
        {this.state.lc == false ? <>
        <Skeleton height={90} width={300}/>
        </> : <>
        <img style={{border:'solid 1px #808080'}} src={'data:image/jpeg;base64,'+this.state.captcha} alt="Imagem não carregada!"/>
       
        </>}
        
        <div>
        
        <button className='btn-cpa' onClick={()=>{
          this.setState({
            lc:false,
            tc:''
          })
          fetch(`${apiUrl}/captcha/${new Date().getTime()}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            }
          })
            .then(response =>{
if(response.status == 403){
toast.info("Você excedeu o número permitido de geração de captcha. Por favor, aguarde alguns minutos antes de tentar novamente.")

return;
}
              
if(response.status == 200){
response.json().then(data=>{
this.setState({
captcha:data.c,
lc:true
})
})
}
          }).catch(()=>{
            toast.error("Falha na comunicação com o servidor!")
          })


           this.setState({
            captcha:`${apiUrl}/captcha/${new Date().getTime()}`,
            c:true
          })




        }}>
          <FontAwesomeIcon icon={faRefresh} color='#fff'/>
        </button>
      
      
        </div>
     
        </div>
      
        <div class="input-group mt-3">
        <input type='text' placeholder='Digite:' style={{textAlign:'center'}}  maxLength={5} value={this.state.tc} onChange={(e)=>{
          this.setState({tc:e.target.value})
        }} className='form-control' disabled={this.state.lc == true ? false : true}/>
         <button class="btn btn-primary" type="button" disabled={this.state.lc == true ? false : true} onClick={(e)=>{
           e.target.classList.add('load')
           fetch(`${apiUrl}/encurtar/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
             l:this.state.l,
             tc:this.state.tc,
            }),
          })
            .then(response =>{

              e.target.classList.remove("load")
             
              if(response.status == 401){
                response.json().then(data=>{
                toast.error(data.error)
                })
                return;
                }

if(response.status == 404){
response.json().then(data=>{
toast.error(data.error)
})
return;
}

if(response.status == 403){
response.json().then(data=>{
toast.info("Você excedeu o número permitido de acesso. Por favor, aguarde alguns minutos antes de tentar novamente.")
})
return;
}



if(response.status == 200){
response.json().then(data=>{
toast.success("Seu link foi encurtado.")
this.setState({e:`https://ectar.link/${data.e}`,c:false,tc:''})
})
}


            }).catch(err=>{
              toast.error("Falha na comunicação com o servidor!")
            })
         }}>Enviar <FontAwesomeIcon icon={faArrowRight} color='#fff'/>
         </button>
</div>
      </div>
      </StaticDialog>

      </>
    );
  }
}

export default Inicio;